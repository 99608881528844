<template>
  <el-form
    ref="form"
    :model="formData"
    :rules="formRules"
    label-width="80px"
  >
    <el-form-item
      label="手机号"
      prop="phone"
    >
      <el-input
        v-model="formData.phone"
        placeholder="输入手机号"
        @keyup.enter.native="onRegist"
      >
      </el-input>
    </el-form-item>
    <el-form-item
      label="验证码"
      prop="verify"
    >
      <div class="verify-box">
        <el-input
          v-model="formData.verify"
          placeholder="输入验证码"
          @keyup.enter.native="onRegist"
        >
        </el-input>
        <el-button
          type="primary"
          :disabled="verifyDisabled"
          @click="onGetVerify"
        >{{ verifyText }}</el-button>
      </div>
    </el-form-item>
    <el-form-item style="margin-top: -15px;">
      <div class="regist-box">
        <!-- <el-link
          type="primary"
          :underline="false"
          @click="$router.push({ name: 'password' })"
        >忘记密码？</el-link> -->
        <span>没有账号？</span>
        <el-link
          type="primary"
          :underline="false"
          @click="$router.push({ name: 'regist' })"
        >立即注册</el-link>
      </div>
    </el-form-item>
    <el-form-item label-width="0">
      <el-button
        type="primary"
        block
        :loading="loading"
        @click.native.prevent="onRegist"
      >登录</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { userLoginByVerify, getSms } from '@/api/admin'

export default {
  data() {
    return {
      loading: false,
      verifyText: '获取验证码',
      verifyDisabled: false,
      verifyTimer: null,
      formData: {
        phone: '',
        verify: '',
      },
      formRules: {
        phone: [
          { required: true, message: '必填项', trigger: 'blur' },
          { validator: this.$validator.isPhone, trigger: 'blur' },
        ],
        verify: [{ required: true, message: '必填项', trigger: 'change' }],
      },
    }
  },
  methods: {
    onRegist() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          userLoginByVerify(this.formData)
            .then(res => {
              return this.$store.dispatch('user/USER_LOGIN', res)
            })
            .then(() => {
              this.$emit('success')
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    onGetVerify() {
      this.$refs.form.validateField('phone', valid => {
        if (!valid) {
          this.verifyDisabled = true
          getSms(this.formData.phone, 'login_verify')
            .then(res => {
              let count = 60
              this.verifyText = `重新获取 ${count}s`
              this.verifyTimer = setInterval(() => {
                if (count < 1) {
                  this.verifyText = '获取验证码'
                  this.verifyDisabled = false
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                } else {
                  count--
                  this.verifyText = `重新获取 ${count}s`
                }
              }, 1000)

              this.$once('hook:beforeDestroy', () => {
                if (this.verifyTimer) {
                  clearInterval(this.verifyTimer)
                  this.verifyTimer = null
                }
              })
            })
            .catch(() => {
              this.verifyDisabled = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.regist-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}

.verify-box {
  display: flex;

  .el-button {
    width: 160px;
  }
}
</style>
