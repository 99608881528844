<template>
  <div class="login-container">
    <div class="login-body">


      <el-card
        class="animate__animated animate__fadeInDown"
        :body-style="{ 'padding': '20px 30px' }"
      >
        <div class="logo"></div>
        <div class="title">{{ title }}</div>
        <div>
           <!-- <el-alert
              title="密码登录方式正在升级维护，暂不支持密码登录。请采用手机号+短信验证码方式登录！"
              type="warning"
              effect="dark">
            </el-alert>
            <br/><br/> -->
          </div>
        <user-password
          v-if="activeName === 'password'"
          @success="onSuccess"
        ></user-password>

        <verify-view
          v-if="activeName === 'verify'"
          @success="onSuccess"
        ></verify-view>

        <div style="text-align: right;">
          <el-link
            class="g-ml-5"
            :type="activeName === 'password' ? 'primary' : ''"
            @click="activeName = 'password'"
          >密码登陆</el-link>
          <el-link
            class="g-ml-15"
            :type="activeName === 'verify' ? 'primary' : ''"
            @click="activeName = 'verify'"
          >手机短信登陆</el-link>
        </div>
      </el-card>

      <div class="logo2"></div>
    </div>
  </div>
</template>

<script>
import UserPassword from './components/user-password'
import VerifyView from './components/verify'

export default {
  components: {
    UserPassword,
    VerifyView,
  },
  data() {
    return {
      loading: false,
      title: '农牧厅精准监督',
      activeName: 'password',
    }
  },
  methods: {
    onAlert(){
      alert("密码登录方式正在升级维护，暂不支持密码登录。请采用手机号+短信验证码方式登录！");
      this.activeName = 'verify';
    },
    onSuccess() {
      this.$store.dispatch('menu/GET_MENU')
      const to = { path: this.$store.state.indexPage }
      this.$route.query.redirect && (to.path = this.$route.query.redirect)

      this.$router.replace(to)
    },
  },
}
</script>

<style lang="scss" scoped>
.login-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url('~@/assets/images/login-bg.jpg') center / cover;

  .login-body {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);

    .logo {
      width: 120px;
      height: 80px;
      margin: 0 auto;
      background: url('~@/assets/images/logo.png') center / contain no-repeat;
    }
    .logo2 {
      width: 150px;
      height: 150px;

      margin: 20px auto;
      background: url('~@/assets/images/app.png') center / contain no-repeat;
    }

    .title {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      color: #000;
      text-align: center;
    }
  }
}
</style>
