/*
 * @Author: jiang
 * @Date: 2021-06-11 00:11:03
 * @Description:
 */

import request from '../axios'

/**
 * 用户登录
 * @param { object } data
 * @param { string } data.phone
 * @param { string } data.password
 * @returns
 */
export function userLogin(data) {
  return request({
    method: 'post',
    url: '/api/authenticate',
    data,
  })
}

/**
 * 用户登录 通过手机验证码
 */
export function userLoginByVerify(data) {
  return request({
    method: 'post',
    url: '/api/users/sms/login',
    data,
  })
}

/**
 * 用户找回密码
 */
export function userPassword(data) {
  return request({
    method: 'post',
    url: '/api/phone/verify/change/password',
    data,
  })
}

/**
 * 用户注册
 */
export function userRegist(data) {
  return request({
    method: 'post',
    url: '/api/users/register',
    data,
  })
}

export function updateUserPasswordByPassword(params) {
  return request({
    method: 'post',
    url: '/api/users/password',
    data: params,
  })
}

/**
 *
 * @param {*} phone
 * @param {*} type - 场景（login_verify：登陆验证码，chage_phone_verify：修改手机号验证码，register_verify：注册验证码）
 * @returns
 */
export function getSms(phone, type) {
  return request({
    method: 'post',
    url: '/api/send/verify/sms',
    data: {
      phone: phone,
      template: type,
    },
  })
}
